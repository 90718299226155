export const SimpleAudio: React.FC<{ path: string }> = ({ path }) => {
  return (
    <li>
      {/* <a href={path} target="_blank" rel="noreferrer">
        <code>{path}</code>
      </a> */}
      <audio controls className="my-3">
        <source src={path} type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>
    </li>
  );
};
