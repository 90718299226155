// permissible length options of "short" | "medium" | "long"
export enum LengthOption {
    SHORT = "5m",
    MEDIUM = "10m",
    LONG = "20m",
}

export enum SessionStep {
    BEGINNING = 0,
    SPACE = 1,
    ISSUES = 2,
    END = 4
}

interface IBaseConfigSection {
    title: string;
    description?: string;
}

interface IConfigSection extends IBaseConfigSection {
    options: IOption[];
}

interface IIssuesConfigSection extends IBaseConfigSection {
    limit: number;
    options: IIssueOption[];
}

interface ITherapistConfigSection extends IBaseConfigSection {
    options: ITherapist[];
}

interface ISpacesConfigSection extends IBaseConfigSection {
    options: ISpaceOption[];
}

interface IMusicConfigSection extends IBaseConfigSection {
    options: IMusicOption[];
}

export interface IConfig {
    paths: {
        base: string;
        audio: string;
        images: string;
    }
    welcome: IBaseConfigSection;
    therapists: ITherapistConfigSection;
    spaces: ISpacesConfigSection;
    issues: IIssuesConfigSection;
    lengths: IConfigSection;
    music: IMusicConfigSection;
    beginnings: string[];
    endings: string[];
}

export interface IOption {
    label: string;
    value: string;
}

export interface ISessionConfig {
    therapist?: ITherapist;
    space?: ISpaceOption;
    issues: IIssueOption[];
    issuesDone?: boolean;
    length?: IOption;
    music?: IMusicOption;
    editing?: boolean;
}

export interface ITherapist extends IOption {
    gender: "male" | "female";
    image: string;
    bio: string;
    preview?: string;
}

export interface ISpaceOption extends IOption {
    join?: string;
}

export interface IIssueOption extends IOption {
    prose: string;
    short?: string;
}

export interface IMusicOption extends IOption {
    prose: string;
}

export interface SelectorProps {
    muted?: boolean;
}