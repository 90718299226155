import { ISessionConfig, ITherapist } from "types";

const mockTherapist: ITherapist = {
  label: "Simon",
  value: "simon",
  gender: "male",
  image: "dr-simon.jpg",
  bio: "Simon is a highly experienced therapist who has been helping people for over 20 years. He has a calm, reassuring voice and a gentle manner.",
};

export const mockData: ISessionConfig = {
  therapist: {
    ...mockTherapist,
  },
  space: {
    label: "Forest glade",
    value: "forest_glade",
  },
  issues: [
    {
      label: "Lose weight",
      value: "weight_loss",
      prose: "wanting to lose weight",
    },
    {
      label: "Identity issues",
      value: "healing",
      prose: "having identity issues",
    },
  ],
  issuesDone: true,
  music: {
    label: "Quiet ambient music",
    value: "subtle",
    prose: "set to quiet ambient music",
  },
  length: {
    label: "10 minutes",
    value: "medium",
  },
};

export const blankSession = {
  therapist: undefined,
  space: undefined,
  issues: [],
  length: undefined,
  editing: false,
};
