import { Button } from "./Button";
import { useContext, useMemo } from "react";
import { ITherapist } from "types";
import classNames from "classnames";
import { GlobalContext } from "GlobalContext";
import { SpeechPreview } from "./SpeechPreview";
import { Check } from "phosphor-react";

interface ITherapistCardProps {
  therapist: ITherapist;
}

export const TherapistCard: React.FC<ITherapistCardProps> = ({ therapist }) => {
  const { label, image, bio } = therapist;
  const { config } = useContext(GlobalContext);
  const imagePath = useMemo(
    () => `${config?.paths.base}/${config?.paths.images}`,
    [config]
  );

  const context = useContext(GlobalContext);
  const { session, setSession } = context;
  const { therapist: selectedTherapist } = session;

  const className = classNames(
    "border-2 p-4 md:p-8 border-white rounded-md cursor-pointer bg-white bg-opacity-10 transition-all duration-200",
    {
      "border-opacity-0": selectedTherapist !== therapist,
      "border-opacity-50": selectedTherapist === therapist,
    }
  );

  const isSelected = useMemo(
    () => selectedTherapist?.value === therapist.value,
    [selectedTherapist, therapist]
  );

  return (
    <div className={className}>
      <div className="therapist-card flex flex-row-reverse md:flex-col gap-4 items-start">
        <div className="img-container relative mb-4">
          <img
            src={`${imagePath}/${image}`}
            alt={label}
            className="block  h-auto rounded-md max-w-32 md:max-w-full"
          />
          <div className="absolute -bottom-12 md:bottom-2 right-0 md:right-2">
            {therapist.preview && <SpeechPreview url={therapist.preview} />}
          </div>
        </div>
        <div className="radio-info">
          <h3 className="font-bold text-xl">{label}</h3>
          <p className="text-sm mb-4">{bio}</p>
          <Button
            size="small"
            onClick={() => setSession({ ...session, therapist })}
            disabled={isSelected}
          >
            {!isSelected && "Choose "}
            {therapist.label}
            {isSelected && <Check size={16} weight="bold" />}
          </Button>
        </div>
      </div>
    </div>
  );
};
