import { Fragment, useContext, useEffect, useMemo } from "react";
import { GlobalContext } from "GlobalContext";
import { useNavigate } from "react-router-dom";
import { CommaSeparatedList } from "./CommaSeparatedList";

interface OverviewProps {
  children?: React.ReactNode | React.ReactNode[];
}

export const Overview: React.FC<OverviewProps> = ({ children }) => {
  const { session, config } = useContext(GlobalContext);
  const navigate = useNavigate();

  const { therapist, space, issues, length } = session;

  // Back to the start if any are missing
  useEffect(() => {
    if (!therapist || !space || issues.length === 0 || !length) {
      navigate("/");
    }
  }, [therapist, space, issues, length, navigate]);

  const lengthDisplay = useMemo(() => {
    const lengthOption = config?.lengths.options.find((l) => l.value === length?.value);
    if (lengthOption) {
      return lengthOption.label.replace("s", "").replace(" ", "-");
    }
  }, [config, length?.value]);

  const selectedSpace = useMemo(() => {
    return config?.spaces.options.find((v) => v.value === space?.value);
  }, [space, config]);

  const selectedTherapist = useMemo(() => {
    return config?.therapists.options.find((v) => v.value === therapist?.value);
  }, [therapist, config]);

  const heOrShe = useMemo(
    () => (selectedTherapist?.gender === "male" ? "He" : "She"),
    [selectedTherapist]
  );

  const spaceDisplay = useMemo(() => {
    if (!selectedSpace) {
      return "";
    }

    const { label, join } = selectedSpace;
    const joinDisplay = join ? ` ${join} ` : " in a ";
    return `${joinDisplay}${label}`;
  }, [selectedSpace]);

  const musicDisplay = useMemo(() => {
    const musicOption = config?.music.options.find(
      (m) => m.value === session.music?.value
    );
    if (musicOption) {
      return ` ${musicOption.prose}`;
    }
    return "";
  }, [config, session.music]);

  const mainIssue = useMemo(() => {
    const issue = issues[0];

    if (/issue/.test(issue.prose)) {
      return `your ${issue.prose.replace("having ", "")}`;
    }

    return `your issue of ${issues[0].prose}`;
  }, [issues]);

  const secondaryIssues = useMemo(() => issues.slice(1), [issues]);

  if (issues.length === 0) {
    return null;
  }

  return (
    <div className="px-4">
      <div className="overview p-4 xl:p-8 border border-white border-opacity-50 rounded-md bg-white bg-opacity-10 shadow-2xl">
        <h1 className="font-bold mb-4 text-2xl">What happens next?</h1>
        <div className="flex flex-col md:flex-row gap-8">
          <div className="flex-1 flex flex-col justify-between">
            <div className="flex-1">
              <p className="mb-4">
                You’ll have a {lengthDisplay} session with {selectedTherapist?.label},
                imagining yourself {spaceDisplay}, {musicDisplay}.
              </p>

              <p className="mb-4">
                {heOrShe} will help you with {mainIssue}
                {secondaryIssues.length > 0 ? (
                  <Fragment>
                    <span>, and will also touch on </span>
                    <CommaSeparatedList items={secondaryIssues.map((i) => i.prose)} />.
                  </Fragment>
                ) : (
                  "."
                )}
              </p>
            </div>
            {children}
          </div>

          <img
            className="md:max-w-xs block rounded-md"
            src={`${config?.paths.base}/${config?.paths.images}/${selectedTherapist?.image}`}
            alt={selectedTherapist?.label}
          />
        </div>
      </div>
    </div>
  );
};
