import { Page } from "./Page";
import { Progress, TherapistSelector } from "../components";

export const TherapistPage: React.FC = () => {
  return (
    <Page>
      <Progress />
      <TherapistSelector />
    </Page>
  );
};
