import { Page } from "./Page";
import { useContext, useEffect } from "react";
import { GlobalContext } from "GlobalContext";
import { useNavigate } from "react-router-dom";
import { Progress, SpaceSelector, StickyNav } from "../components";

export const SpacePage: React.FC = () => {
  const navigate = useNavigate();

  const { session } = useContext(GlobalContext);

  useEffect(() => {
    if (!session.therapist) {
      navigate("/");
    }
  }, [session, navigate]);

  return (
    <Page>
      <Progress />
      <SpaceSelector />
      <StickyNav to="/issues" disabled={!session.space} />
    </Page>
  );
};
