import { Page } from "./Page";
import React, { useContext } from "react";
import { GlobalContext } from "../GlobalContext";
import { TherapistSelector, StickyNav } from "../components";

export const Home: React.FC = () => {
  const context = useContext(GlobalContext);
  const { session, config } = context;
  const { therapist } = session;

  if (!config || !context) {
    return null;
  }

  const { welcome } = config;

  return (
    <Page title={welcome.title}>
      <p className="text-sm md:text-base mb-4 px-4">{welcome.description}</p>
      <TherapistSelector />
      <StickyNav to="/space" disabled={!therapist} label="Next" hideBack />
    </Page>
  );
};
