import { useContext } from "react";
import { InputGroup } from "./InputGroup";
import { GlobalContext } from "GlobalContext";
import { TherapistCard } from "./TherapistCard";
import { SelectorProps, ITherapist } from "types";
import { SoundContextProvider } from "SoundContext";

export const TherapistSelector: React.FC<SelectorProps> = () => {
  const context = useContext(GlobalContext);

  if (!context?.config) {
    return null;
  }

  const { therapists } = context.config;

  return (
    <SoundContextProvider>
      <InputGroup title={therapists.title}>
        <div className="grid md:grid-cols-2 gap-8">
          {therapists.options.map((option: ITherapist) => (
            <TherapistCard therapist={option} key={option.value} />
          ))}
        </div>
      </InputGroup>
    </SoundContextProvider>
  );
};
