import classNames from "classnames";

interface ProgressCardProps {
  title: string;
  muted?: boolean;
  active?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  children?: React.ReactNode | React.ReactNode[];
}

export const ProgressCard: React.FC<ProgressCardProps> = ({
  title,
  children,
  muted,
  active,
  disabled,
  onClick,
}) => {
  const className = classNames(
    "progress-card flex text-[10px] py-2 px-3 lg:px-4 lg:text-sm text-white rounded-md text-center justify-center",
    {
      "opacity-50 bg-white bg-opacity-10": muted,
      "bg-ph-pink bg-opacity-30 items-start": !!children,
      "bg-white bg-opacity-20 border-opacity-30": !children && !muted,
      "pointer-events-none": disabled,
      "ring-2 ring-ph-pink": active,
      "items-center": !!!children,
    }
  );

  const headingClass = classNames({
    "text-ph-pink": !!children,
    "text-white": !children,
  });

  return (
    <li className={className}>
      <button onClick={onClick}>
        <h2 className={headingClass}>{title}</h2>
        {children}
      </button>
    </li>
  );
};
