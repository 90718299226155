import classNames from "classnames";
import React from "react";

interface RadioButtonProps {
  label: string;
  value: string;
  checked: boolean;
  onChange: (value: string) => void;
  children?: React.ReactNode | React.ReactNode[];
  muted?: boolean;
}

export const RadioButton: React.FC<RadioButtonProps> = ({
  label,
  value,
  checked,
  onChange,
  children,
  muted,
}) => {
  const classNm = classNames("component radio", {
    "has-image": !!children,
    muted,
  });

  return (
    <div className={classNm}>
      <label>
        <input
          type="radio"
          value={value}
          checked={checked}
          onChange={() => onChange(value)}
        />
        {children}
        {!children && <span>{label}</span>}
      </label>
    </div>
  );
};
