import { IConfig } from "types";

const loadConfig = async () => {
  const urlParams = new URLSearchParams(window.location.search);
  const configUrl =
    urlParams.get("config") ||
    process.env.REACT_APP_CONFIG_URL ||
    "/assets/data/config.json";

  console.log(
    "Loading config from:",
    configUrl,
    "Current env is:",
    process.env.NODE_ENV,
    process.env.REACT_APP_CONFIG_URL
  );

  try {
    const response = await fetch(configUrl);
    const config = await response.json();
    return config as IConfig;
  } catch (error) {
    console.error("Failed to load config:", error);
  }
};

export default loadConfig;
