import { Page } from "./Page";
import { useContext, useEffect } from "react";
import { GlobalContext } from "GlobalContext";
import { useNavigate } from "react-router-dom";
import { IssueSelector, Progress, StickyNav } from "../components";

export const IssuesPage: React.FC = () => {
  const navigate = useNavigate();
  const { session } = useContext(GlobalContext);

  useEffect(() => {
    if (!session.therapist) {
      navigate("/");
    }
  }, [session, navigate]);

  return (
    <Page>
      <Progress />
      <IssueSelector />
      <StickyNav to="/length" disabled={session.issues.length === 0} />
    </Page>
  );
};
