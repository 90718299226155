import loadConfig from "data/api";
import { LoadScreen } from "pages/LoadScreen";
import { blankSession } from "data/constants";
import { IConfig, ISessionConfig } from "./types.d";
import React, { createContext, useState, ReactNode, useEffect } from "react";

interface GlobalContextProps {
  config?: IConfig;
  setConfig: (config: IConfig) => void;
  session: ISessionConfig;
  setSession: (session: ISessionConfig) => void;
}

export const GlobalContext = createContext<GlobalContextProps>({
  config: undefined,
  setConfig: () => {},
  session: blankSession,
  setSession: () => {},
});

export const GlobalProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [config, setConfig] = useState<IConfig | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);
  const [session, setSession] = useState<ISessionConfig>({ ...blankSession });

  useEffect(() => {
    loadConfig().then((data?: IConfig) => {
      if (data) {
        setConfig(data);
      }

      setLoading(false);
    });
  }, []);

  if (loading) {
    return <LoadScreen />;
  }

  return (
    <GlobalContext.Provider
      value={{
        config,
        setConfig,
        session,
        setSession,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
