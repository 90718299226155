import { useNavigate } from "react-router-dom";
import { SessionStep } from "./types.d";
import { createContext, useState, ReactNode, useCallback } from "react";

interface SessionContextProps {
  current: SessionStep;
  setCurrent: (step: SessionStep) => void;
  running: boolean;
  setRunning: (running: boolean) => void;
  nextStep: () => void;
}

export const SessionContext = createContext<SessionContextProps>({
  current: SessionStep.BEGINNING,
  setCurrent: () => {},
  running: false,
  setRunning: () => {},
  nextStep: () => {},
});

export const SessionProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [current, setCurrent] = useState(SessionStep.BEGINNING);
  const [running, setRunning] = useState(false);
  const navigate = useNavigate();

  const nextStep = useCallback(() => {
    const next = current + 1;

    if (next > SessionStep.END) {
      navigate("/finish");
    } else {
      setCurrent(next);
    }
  }, [current, navigate]);

  return (
    <SessionContext.Provider
      value={{
        current,
        setCurrent,
        nextStep,
        running,
        setRunning,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};
