import classNames from "classnames";

interface CheckboxProps {
  label: string;
  checked: boolean;
  onChange: () => void;
  disabled?: boolean;
}

export const Checkbox: React.FC<CheckboxProps> = ({
  label,
  checked,
  disabled,
  onChange,
}) => {
  const className = classNames("component checkbox", {
    "opacity-50": disabled,
  });

  return (
    <div className={className}>
      <label>
        <input
          type="checkbox"
          checked={checked}
          disabled={disabled}
          onChange={onChange}
        />
        {label}
      </label>
    </div>
  );
};
