import classNames from "classnames";
import { SoundContext } from "SoundContext";
import { GlobalContext } from "GlobalContext";
import { useContext, useEffect, useMemo, useRef } from "react";
import { SpeakerHigh, SpeakerX } from "phosphor-react";

interface ISpeechPreviewProps {
  url: string;
}

export const SpeechPreview: React.FC<ISpeechPreviewProps> = ({ url }) => {
  const { playingId, setPlayingId } = useContext(SoundContext);
  const { config } = useContext(GlobalContext);
  const audioPath = config?.paths.audio;
  const audioRef = useRef<HTMLAudioElement>(null);

  const isPreviewingAudio = useMemo(() => playingId === url, [playingId, url]);

  const classNm = classNames(
    "w-10 h-10 md:w-12 md:h-12 border-2 rounded-full hover:border-opacity-100 bg-ph-purple transition backdrop-blur-md shadow-xl flex items-center justify-center",
    {
      "bg-opacity-30 bg-ph-pink border-ph-pink border-opacity-100": isPreviewingAudio,
      "bg-opacity-10 hover:bg-opacity-30 border-white border-opacity-50":
        !isPreviewingAudio,
    }
  );

  useEffect(() => {
    if (audioRef.current) {
      if (isPreviewingAudio) {
        audioRef.current.play();
      } else {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      }
    }
  }, [isPreviewingAudio]);

  return (
    <button
      onClick={() => setPlayingId(isPreviewingAudio ? undefined : url)}
      className={classNm}
    >
      {isPreviewingAudio && <SpeakerX size={24} className="slow-pulse" />}
      {!isPreviewingAudio && <SpeakerHigh size={24} />}
      <audio
        ref={audioRef}
        src={`${audioPath}/${url}`}
        onEnded={() => setPlayingId(undefined)}
      />
    </button>
  );
};
