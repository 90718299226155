import classNames from "classnames";

interface ButtonProps {
  children: React.ReactNode | React.ReactNode[];
  onClick: () => void;
  type?: "button" | "submit" | "reset";
  size?: "small" | "medium" | "large";
  variant?: "primary" | "secondary";
  disabled?: boolean;
}

export const Button: React.FC<ButtonProps> = ({
  children,
  onClick,
  type = "button",
  size = "medium",
  variant = "primary",
  disabled = false,
}) => {
  const className = classNames(
    "text-white rounded-md text-base transition duration-300 ease-in-out transform hover:bg-ph-accent-dark flex gap-2 items-center justify-center max-w-sm",
    {
      "py-3 px-6 text-base": size === "medium",
      "py-2 px-6 text-sm": size === "small",
      "opacity-50 cursor-not-allowed": disabled,
      "bg-ph-accent": variant === "primary",
      "border-white border border-opacity-50 hover:border-ph-accent-dark": variant === "secondary",
    }
  );

  return (
    <button type={type} onClick={onClick} className={className} disabled={disabled}>
      {children}
    </button>
  );
};
