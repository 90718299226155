import { Page } from "./Page";
import { GlobalContext } from "GlobalContext";
import { useNavigate } from "react-router-dom";
import { SessionProvider } from "SessionContext";
import { useContext, useEffect, useMemo } from "react";
import { SimpleAudio } from "../components/SimpleAudio";

export const SessionView: React.FC = () => {
  const { config, session } = useContext(GlobalContext);

  const navigate = useNavigate();

  const { therapist, space, issues, length, music } = session;

  useEffect(() => {
    if (!therapist || !space || issues.length === 0 || !length || !music) {
      navigate("/");
    }
  }, [therapist, space, issues, length, music, navigate]);

  const baseTherapistPath = useMemo(() => {
    if (!config || !therapist) {
      return null;
    }

    const { base, audio } = config.paths;
    return `${base}/${audio}/therapists/${therapist?.value}`;
  }, [config, therapist]);

  const audioPath = useMemo(() => {
    if (!config) {
      return null;
    }

    const { base, audio } = config.paths;
    return `${base}/${audio}/music/${session.music?.value}`;
  }, [config, session]);

  const issuePaths = useMemo(() => {
    if (!config || !therapist || issues.length === 0 || !length) {
      return [];
    }

    // Always have the first issue full length, and the rest short
    return issues.map((issue, index) => {
      return `${baseTherapistPath}/issues/${issue.value}/${therapist?.value}_${
        issue.value
      }_${index === 0 ? length?.value : "short"}.mp3`;
    });
  }, [config, therapist, issues, length, baseTherapistPath]);

  if (issues.length === 0 || !config) {
    return null;
  }

  return (
    <SessionProvider>
      <Page title="Let’s begin...">
        <div className="px-4">
          <div className="img-container relative mb-4">
            <img
              src={`${config?.paths.images}/${therapist?.image}`}
              alt={therapist?.label}
              className="block h-auto rounded-md max-w-32 md:max-w-full"
            />
          </div>
          <h2 className="font-bold mb-4">Session sequence monitor</h2>

          <div className="border-y py-4">
            <h3 className="text-ph-pink font-bold">Beginning</h3>
            <ul>
              <SimpleAudio
                path={`${baseTherapistPath}/beginning/${therapist?.value}_beginning_${length?.value}.mp3`}
              />
            </ul>
          </div>

          <div className="border-b py-4">
            <h3 className="text-ph-pink font-bold">Music</h3>
            <ul>
              {["beginning", "space", "issues"].map((section) => (
                <SimpleAudio
                  path={`${audioPath}/${music?.value}_${section}.mp3`}
                  key={section}
                />
              ))}
            </ul>
          </div>

          <div className="border-b py-4">
            <h3 className="text-ph-pink font-bold">Issues</h3>
            <ul>
              {issuePaths.map(
                (path) => {
                  return <SimpleAudio path={path} key={path} />;
                },
                [issues]
              )}
            </ul>
          </div>

          <div className="border-b py-4">
            <h3 className="text-ph-pink font-bold">End</h3>
            <ul>
              <SimpleAudio
                path={`${baseTherapistPath}/end/${therapist?.value}_end_${length?.value}.mp3`}
              />
            </ul>
          </div>

          <div className="py-4">
            <h3 className="text-ph-pink font-bold">Full session data</h3>
            <code className="text-xs">{JSON.stringify(session)}</code>
          </div>
        </div>
      </Page>
    </SessionProvider>
  );
};
