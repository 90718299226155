interface PageProps {
  title?: string;
  children: React.ReactNode | React.ReactNode[];
}

export const Page: React.FC<PageProps> = ({ title, children }) => {
  return (
    <div className="pt-4 lg:py-12 min-h-screen dark:bg-ph-purple dark:text-white flex flex-col">
      <div className="container max-w-3xl mx-auto flex-1 flex flex-col">
        {title && <h1 className="text-2xl font-bold mb-4 px-4">{title}</h1>}
        {children}
      </div>
    </div>
  );
};
