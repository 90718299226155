import React from "react";

interface CommaSeparatedListProps {
  items: string[];
}

export const CommaSeparatedList: React.FC<CommaSeparatedListProps> = ({ items }) => {
  if (items.length === 0) {
    return null;
  }

  if (items.length === 1) {
    return <span>{items[0]}</span>;
  }

  const formattedList = items.slice(0, -1).join(", ") + " and " + items[items.length - 1];

  return <span>{formattedList}</span>;
};
