import { useContext, useMemo } from "react";
import { InputGroup } from "./InputGroup";
import { RadioButton } from "./RadioButton";
import { GlobalContext } from "GlobalContext";
import { SelectorProps, IOption } from "types";

export const SpaceSelector: React.FC<SelectorProps> = ({ muted }) => {
  const context = useContext(GlobalContext);
  const { config, session, setSession } = context;

  const title = useMemo(() => {
    if (session.therapist) {
      return `${config?.spaces.title.replace("?", "")} with ${session.therapist.label}?`;
    }
    return config?.spaces.title;
  }, [session.therapist, config]);

  if (!config || !context) {
    return null;
  }

  return (
    <InputGroup title={title}>
      {config.spaces.options.map((option: IOption) => (
        <RadioButton
          key={option.value}
          label={option.label.charAt(0).toUpperCase() + option.label.slice(1)}
          value={option.value}
          checked={session.space?.value === option.value}
          onChange={() => setSession({ ...session, space: option })}
          muted={muted}
        />
      ))}
    </InputGroup>
  );
};
