import { Checkbox } from "./Checkbox";
import { InputGroup } from "./InputGroup";
import { GlobalContext } from "GlobalContext";
import { IIssueOption, SelectorProps } from "types";
import { useCallback, useContext, useMemo } from "react";

export const IssueSelector: React.FC<SelectorProps> = ({ muted }) => {
  const context = useContext(GlobalContext);
  const { config, session, setSession } = context;

  const { issues, therapist } = session;

  const title = useMemo(() => {
    if (!therapist) {
      return config?.issues.title;
    }

    const mainTitle = config?.issues.title.replace("{therapist}", therapist.label);

    return `${mainTitle} (Choose up to ${config?.issues.limit})`;
  }, [therapist, config?.issues]);

  const _toggleIssue = useCallback(
    (option: IIssueOption) => {
      if (issues === undefined) {
        return;
      }

      const updatedIssues = [...issues];
      const index = updatedIssues.findIndex((issue) => issue.value === option.value);

      if (index > -1) {
        updatedIssues.splice(index, 1);
      } else {
        updatedIssues.push(option);
      }

      setSession({ ...session, issues: updatedIssues });
    },
    [issues, session, setSession]
  );

  if (!config || !context) {
    return null;
  }

  return (
    <div className="pb-12">
      <InputGroup title={title} muted={muted}>
        {config.issues.options.map((option: IIssueOption) => (
          <Checkbox
            key={option.value}
            disabled={
              issues.length >= config.issues.limit &&
              !issues.find((issue) => issue.value === option.value)
            }
            label={`${option.label.charAt(0).toUpperCase()}${option.label.slice(1)}`}
            checked={!!issues.find((issue) => issue.value === option.value)}
            onChange={() => _toggleIssue(option)}
          />
        ))}
      </InputGroup>
      <div className="flex items-center gap-4 p-4">
        {/* <Button
          disabled={issues.length === 0}
          onClick={() => setSession({ ...session, issuesDone: true })}
        >
          Done
        </Button> */}
        {issues.length === 0 && (
          <p>Please choose at least one issue from the list above</p>
        )}
      </div>
    </div>
  );
};
