import React, { createContext, useState, ReactNode } from "react";

interface SoundContextProps {
  playingId?: string;
  setPlayingId: (id?: string) => void;
}

export const SoundContext = createContext<SoundContextProps>({
  playingId: undefined,
  setPlayingId: () => {},
});

export const SoundContextProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [playingId, setPlayingId] = useState<string | undefined>(undefined);

  return (
    <SoundContext.Provider
      value={{
        playingId,
        setPlayingId,
      }}
    >
      {children}
    </SoundContext.Provider>
  );
};
