import { useContext } from "react";
import { ProgressCard } from "./ProgressCard";
import { GlobalContext } from "GlobalContext";
import { useLocation, useNavigate } from "react-router-dom";

export const Progress: React.FC = () => {
  const { session, setSession } = useContext(GlobalContext);
  const { therapist, space, issues, length, issuesDone } = session;
  const { pathname } = useLocation();
  const navigate = useNavigate();

  return (
    <ol className="px-4 list-decimal flex mb-4 gap-2 lg:gap-4 sticky top-0 bg-ph-purple py-4 z-10 w-full">
      {/* Therapist */}
      <ProgressCard
        title="Therapist"
        onClick={() => navigate("/")}
        active={pathname === "/"}
      >
        {therapist && <p className="italic">{therapist?.label}</p>}
      </ProgressCard>

      {/* Safe Space */}
      <ProgressCard
        title="Space"
        muted={!therapist && !space}
        active={pathname === "/space"}
        onClick={() => navigate("/space")}
      >
        {space && (
          <p className="italic">{`${space?.label
            .charAt(0)
            .toUpperCase()}${space?.label.slice(1)}`}</p>
        )}
      </ProgressCard>

      {/* Issues */}
      <ProgressCard
        title="Issues"
        active={pathname === "/issues"}
        muted={!issuesDone && pathname !== "/issues"}
        onClick={() => navigate("/issues")}
      >
        {issues.length > 0 && (
          <div className="italic">
            {issues[0].short || issues[0].label}
            {issues.length > 1 && <span> +{issues?.length - 1}</span>}
          </div>
        )}
      </ProgressCard>

      {/* Session Length */}
      <ProgressCard
        title="Time"
        active={pathname === "/length"}
        muted={!issuesDone && !length}
        onClick={() => setSession({ ...session, length: undefined })}
      >
        {length && <p className="italic">{length?.label}</p>}
      </ProgressCard>
    </ol>
  );
};
