import { Page } from "./Page";
import { useContext, useEffect } from "react";
import { GlobalContext } from "GlobalContext";
import { useNavigate } from "react-router-dom";
import { LengthSelector, MusicSelector, Progress, StickyNav } from "../components";

export const LengthPage: React.FC = () => {
  const navigate = useNavigate();
  const { session, setSession } = useContext(GlobalContext);

  useEffect(() => {
    if (!session.therapist) {
      navigate("/");
    }

    if (!session.issuesDone) {
      const updatedSession = {
        ...session,
        issuesDone: session.issues.length > 0,
      };
      setSession(updatedSession);
    }
  }, [session, setSession, navigate]);

  return (
    <Page>
      <Progress />
      <div className="flex-1">
        <LengthSelector />
        <MusicSelector />
      </div>
      <StickyNav to="/summary" disabled={!session.length || !session.music} />
    </Page>
  );
};
