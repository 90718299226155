import React from "react";
import {
  Home,
  TherapistPage,
  SpacePage,
  IssuesPage,
  LengthPage,
  SummaryPage,
  SessionView,
} from "./pages";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

const AppRouter: React.FC = () => (
  <Router>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/therapist" element={<TherapistPage />} />
      <Route path="/space" element={<SpacePage />} />
      <Route path="/issues" element={<IssuesPage />} />
      <Route path="/length" element={<LengthPage />} />
      <Route path="/summary" element={<SummaryPage />} />
      <Route path="/session" element={<SessionView />} />
    </Routes>
  </Router>
);

export default AppRouter;
